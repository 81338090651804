import Header from "../Header"
import Home from "./Home"
import ImportanceMedical from "./ImportanceMedical"
import Homogenous from "./Homogenous"
import Contact from '../Contact'

const PeepForMedical = () => {
    return (
        <>
            <Header logo='blue'/>
            <Home/>
            <ImportanceMedical/>
            <Homogenous/>
            <Contact/>
        </>
    )
}


export default PeepForMedical