import React from "react";
import styles from './index.module.css'
import GirlDashboard from '../../../../src/assets/FinTech/2.-Need-for-higher-efficiency.jpg'
import City from '../../../../src/assets/FinTech/3.-Multiple-source.jpg'
import RoboArm from '../../../../src/assets/FinTech/4.-Managing.jpg'

import DocumentMapping from '../../../../src/assets/FinTech/5.-Document-Mapping.jpg'
import DiscrepancyDetection from '../../../../src/assets/FinTech/6.Discrepancy-Detection.jpg'

const Products = () => {
    return (
        <div className={styles['products']} id='products'>
            <div>
                {/* <div className={styles['big-green']}></div>
                <div className={styles['green-dot']}></div>
                <div className={styles['blue-dot']}></div> */}
                <div className={styles['what-heading']}> Modern finance has become much more complicated.</div>
            </div>
            <div className={styles['enable-para']}> 
            Save your teams from manual work during the month-end close.
            </div>
            <div className={styles['product']}>
                <div className={styles['headings']}>
                    {/* <div className={styles['green-heading']}>Real time</div> */}
                    <div className={styles['normal-heading']}><span style={{color: '#01C581'}}>Need For </span>higher efficiency</div>
                    <div className={styles['para']}>Finance teams need to keep up with the breakneck speed of business movement.</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={GirlDashboard} alt="Dashboard"/>
                </div>
            </div>
            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['normal-heading']}><span style={{color: '#01C581'}}>Multiple source </span>of truth</div>
                    <div className={styles['para']}>Data from multiple sources like ERps to CRM  paralyzes accounting systems.</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={City} alt="Security"/>
                </div>
            </div>
            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['normal-heading']}><span style={{color: '#01C581'}}>Managing </span>the volume</div>
                    <div className={styles['para']}>High work loads can demand high manpower requirements.</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={RoboArm} alt="Real Time AI"/>
                </div>
            </div>
            <div>
                {/* <div className={styles['big-green']}></div>
                <div className={styles['green-dot']}></div>
                <div className={styles['blue-dot']}></div> */}
                <div className={styles['what-heading']}> How Do we help you</div>
            </div>

            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['normal-heading']}><span style={{color: '#01C581'}}>Document </span>Mapping</div>
                    <div className={styles['para']}>We segregate the documents be it digital or manual entry and map it to the source document with the right unique identifies.</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={DocumentMapping} alt="Real Time AI"/>
                </div>
            </div>

            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['normal-heading']}><span style={{color: '#01C581'}}>Discrepancy </span>Detection</div>
                    <div className={styles['para']}>We match common financial transaction documents like invoice, purchase order or GRN against each other to detect any anomaly or discrepancies.</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={DiscrepancyDetection} alt="Real Time AI"/>
                </div>
            </div>
        </div>
    )
}


export default Products