import React from "react";
import styles from './index.module.css'
import LaptopImage from '../../../assets/medical/medical_ai_banner.png'

const Home = () => {
    return (
        <div className={styles['control-line']} id='home'>
            <div className={styles['headings-div']}>
                <div className={styles['headings']}>PEEP FOR MEDICAL</div>
                <div className={styles['heading']}>A new frontier in </div>
                <div className={styles['orange-heading']}>healthcare</div>
            </div>
            <div>
                <div className={styles['circle']}>
                    <img src={LaptopImage} alt="Control Line"/>
                </div>
            </div>
        </div>
    )
}

export default Home