import React from "react";
import styles from './index.module.css'
import GirlDashboard from '../../../src/assets/SS_1204727551.jpg'
import City from '../../../src/assets/SS_294067652.jpg'
import RoboArm from '../../../src/assets/robot-hand-side-view-background-presenting-technology-gesture.jpg'

const Products = () => {
    return (
        <div className={styles['products']} id='products'>
            <div>
                {/* <div className={styles['big-green']}></div>
                <div className={styles['green-dot']}></div>
                <div className={styles['blue-dot']}></div> */}
                <div className={styles['what-heading']}> What do we do</div>
            </div>
            <div className={styles['enable-para']}> 
                We enable real time AI Observability to achieve healthy AI solutions, 
                fewer incidents and happy customers
            </div>
            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['green-heading']}>Real time</div>
                    <div className={styles['normal-heading']}>observability & accountability</div>
                    <div className={styles['para']}>It’s not months or weeks but in real time we can find accuracy of platform and models</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={GirlDashboard} alt="Dashboard"/>
                </div>
            </div>
            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['normal-heading']}>Secure and massively</div>
                    <div className={styles['green-heading']}>Scalable</div>
                    <div className={styles['para']}>End-to-end encrypted with 3FA powered role and permission based decryption</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={City} alt="Security"/>
                </div>
            </div>
            <div className={styles['product']}>
                <div className={styles['headings']}>
                    <div className={styles['green-heading']}>Real time AI</div>
                    <div className={styles['normal-heading']}>solution reliable response</div>
                    <div className={styles['para']}>Multiple human check the response to remove any human error, a latency is maintained as the same order of a machine</div>
                </div>
                <div className={styles['rectangle']}>
                    <img src={RoboArm} alt="Real Time AI"/>
                </div>
            </div>
        </div>
    )
}


export default Products