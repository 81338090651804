import React from "react";
import styles from './index.module.css'
import LogoImageGreen from '../../assets/Deep-Reality-Logo.svg'
import LogoImageBlue from '../../assets/Deep-Reality-Logo-Blue.svg'
import {useNavigate } from "react-router-dom";

const Contact = (props) => {
    const navigate = useNavigate()
    return (
        <div className={styles['contact']} id='contact'>
            <div className={styles['logo-div']}>
                <div className={styles['logo']}>
                    <img src={props.logo === 'green' ? LogoImageGreen : LogoImageBlue} alt="Control Line" height={100} width={100}/>
                    {/* <div>All Rights Reserved</div> */}
                </div>
                <div>
                    All Rights Reserved
                </div>
            </div>
            <div className={styles['links']}>
                <div onClick={() => navigate('/')}>Deep Reality</div>
                <div onClick={() => navigate('/fintech')}>Fin Tech</div>
                <div onClick={() => navigate('/peep-for-medical')}>AI for Bio Medical Device</div>
            </div>
            {/* <div className={styles['address']}>
                <div>Address</div>
                <div>Uppal, Hyderabad</div>
            </div> */}
        </div>
    )
}

export default Contact