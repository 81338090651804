
import styles from './index.module.css'

import qualityImage from '../../../assets/medical/quality_inspections.png';
import chronicImage from '../../../assets/medical/chronic_disease.png';
import endToEndImage from '../../../assets/medical/end_to_end_data_encryptions.png';
import visualImage from '../../../assets/medical/visual_compliance.png';
import medicalImage from '../../../assets/medical/medical_image.png';
import medicalAutomationImage from '../../../assets/medical/medicl_automation.png';
import wearableImage from '../../../assets/medical/iwearable_technology.png';


const Solutions = {
    'quality': {
        'image': qualityImage,
        'title': 'Quality Inspection During Manufacturing',
        'para': 'Keen-eyed quality inspection during the manufacturing process ensures at most safety during the entire manufacturing process'
    },
    'chronic': {
        'image': chronicImage,
        'title': 'Chronic Disease Management',
        'para': 'Allow your patients to enjoy life by managing symptoms of long-term disease and slowing down the progression'
    },
    'end-to-end': {
        'image': endToEndImage,
        'title': 'End-to-End Data Encryption',
        'para': 'Maintain doctor-patient confidentiality using our End-to-End data encryption. That is not only impenetrable but swift as well'
    },
    'visual': {
        'image': visualImage,
        'title': 'Visual Compliance Checks',
        'para': 'Our Solution enables you to check visual compliance in the manufacturing pipeline. You can now monitor every minute detail'
    },
    'medical-image': {
        'image': medicalImage,
        'title': 'Medical Image Analysis Assistance',
        'para': 'We help Medical professionals with medical image Analysis Assistance to help them with a quick and accurate diagnosis'
    },
    'medical-automation': {
        'image': medicalAutomationImage,
        'title': 'Medical Automation',
        'para': 'We provide technologies like automated sensors, controller data monitoring and analysis to increase efficiency and accuracy'
    },
    'wearable': {
        'image': wearableImage,
        'title': 'Wearable Technologies',
        'para': "You can track your patient's health and well-being in real-time with the hassle of regular visits and provide necessary intervention"
    },
}

const ImportanceMedical = () => {
    return (
        <div>
            <div className={styles['what']}>
                What do we do
            </div>
            <div className={styles['bio-medical']}>Soham AI for BioMedical Devices</div>
            <div className={styles['solution']}>Our Solution</div>
            <div className={styles['grid']}>
                {Object.keys(Solutions).map((key, index) => {
                    return(
                        <div key={key} className={styles['each-solution']}>
                            <div className={styles['solution-image']}>
                                <img src={Solutions[key]['image']} alt='key' width={70}/>
                            </div>
                            <div>
                                <div className={styles['solution-title']}>{Solutions[key]['title']}</div>
                                <div className={styles['solution-para']}>{Solutions[key]['para']}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ImportanceMedical