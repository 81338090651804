import React from "react";

import Home from "../Home";
import Products from "../Products";
import Contact from "../Contact";
import Importance from "../Importance";
import Header from "../Header";


const DeepReality = () => {
    return (
        <>
            <Header logo = 'green'/>
            <Home/>
            <Products/>
            <Importance/>
            <Contact logo = 'green'/>
        </>
    )
}

export default DeepReality