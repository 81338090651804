import React, {useState} from "react";
import styles from './index.module.css'
// import LogoImage from '../../assets/ai-ci-cd-logo.png'
import LogoImageGreen from '../../assets/Deep-Reality-Logo.svg'
import LogoImageBlue from '../../assets/Deep-Reality-Logo-Blue.svg'
import {useNavigate } from "react-router-dom";


const Header = (props) => {
    const Products = {
        'medical': {
            'title': 'Peep For Medical',
            'link' : '/peep-for-medical'
        },
        'fintech': {
            'title': 'Fin Tech',
            'link' : "/fintech"
        },
        'deepreality': {
            'title': 'Deep Reality',
            'link': '/'
        }
    }

    const smoothScroll = (event) => {
        event.preventDefault()

        const id = event.currentTarget.getAttribute('href').slice(1)
        const $anchor = document.getElementById(id);
        const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;

        window.scroll({
            top: offsetTop - 70,
            behavior: 'smooth'
        })
    }

    const [showSelect, setShowSelect] = useState(false)
    const [isHovering, setIsHovering] = useState(false)
    const navigate = useNavigate()

    const handleHover = () => {
        setShowSelect(true)
    } 

    const handleOut = () => {
        // setShowSelect(false)
        setTimeout(() => {
            // console.log(isHovering)
            if(!isHovering){
                setShowSelect(false)
            }
        }, 1000);
    }

    const handleListHover = () => {
        setIsHovering(true)
    }

    const handleListOut = () => {
        setIsHovering(false)
    }

    

    const handleSelectChange = (key) => {
        setShowSelect(false)
        navigate(Products[key]['link'])

    }

    return (
        <div className={styles['header']}>
            <div className={styles['logo']} href="#home" onClick={smoothScroll}>
                    <img src={props.logo === 'green' ? LogoImageGreen : LogoImageBlue} width='100' height='100' alt="Control Line"/> :
                    
            </div>
            <div className={styles['links']}>
                <a href="#home" onClick={smoothScroll} className={styles['link']}>Home</a>
                <div className={styles['link']} onMouseOver = {handleHover} onMouseOut = {handleOut}
                // onMouseLeave = {() => setShowSelect(false)}
                > Products
                    {showSelect && 
                    <ul className={styles['selected']} 
                        onMouseOver = {handleListHover} onMouseOut = {handleListOut}
                        >
                        {Object.keys(Products).map((key) => (
                            <li key ={key} className={styles['each-title']} onClick={() => handleSelectChange(key)}>
                                {Products[key]['title']}
                            </li>
                        ))}
                    </ul>}
                </div>
                <a href="#contact" onClick={smoothScroll}  className={styles['link']}>Contact Us</a>
            </div>
        </div>
    )
}

export default Header