import React from "react";
import styles from './index.module.css'
import Analytics from '../../assets/digital-tablet-screen-smart-tech@2x.png'
import BusinessImage from '../../assets/shutterstock_635021405 Frame@2x.png'
import TechnologyImage from '../../assets/portrait-african-american-programer-online-cloud-computing-laptop-sitting-desk-with-multiple-screens-parsing-code-shoulder-view-app-developer-working-@2x.png'
import FinanceImage from '../../assets/diverse-business-colleagues-shoo Frame@2x.png'

const Importance = () => {

    return (
        <>
            <div className={styles['intuitive']}>
                <div className={styles['health-para']}>Intuitive Dashboards for monitoring transaction health</div>
                <div className={styles['circle']}>
                    <img src={Analytics} alt="Analytics"/>
                </div>
            </div>
            <div className={styles['monitoring-uses']}>
                <div className={styles['heading']}>Why monitoring of ML models in production matters?</div>
                <div className={styles['para']}>ML models fail all the time, the key is to catch the issue before it becomes a critical problem</div>
                <div className={styles['use-cases']}>
                    <div className={styles['use-case']}>
                        <div className={styles['usecase-circle']}>
                            <img src={BusinessImage} alt="business"/>
                        </div>
                        <div className={styles['usecase-heading']}>Business</div>
                        <div className={styles['usecase-para']}>Wrong responses of ML solutions could be of high impact</div>
                    </div>
                    <div className={styles['use-case']}>
                        <div className={styles['usecase-circle']}>
                            <img src={TechnologyImage} alt='technology'/>
                        </div>
                        <div className={styles['usecase-heading']}>Technology</div>
                        <div className={styles['usecase-para']}>Loss of Productivity of Data Scientists</div>
                    </div>
                    <div className={styles['use-case']}>
                        <div className={styles['usecase-circle']}>
                            <img src={FinanceImage} alt="finance"/>
                        </div>
                        <div className={styles['usecase-heading']}>Finance</div>
                        <div className={styles['usecase-para']}>Increased Solution Cost</div>
                    </div>
                </div>
            </div>
            <div className={styles['kyc-headers']}>
                <div className={styles['kyc-header']}>Our solution solved the gigantic problem of Automated KYC</div>
                <div className={styles['kyc-para']}>Reach out to us to know how our solution solved this gigantic problems</div>
                <a className={styles['kyc-button']} target="_blank" rel="noreferrer" href='https://docs.google.com/forms/d/1zZFpSsXX_3mKWZVssudT-TfpYSxdtp-CY0v9fS7eNxk/viewform?edit_requested=true'>CONTACT US</a>
            </div>
        </>
    )
}

export default Importance