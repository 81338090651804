import React from "react";
import styles from './index.module.css'
import LaptopImage from '../../assets/Mask Group 1@2x.png'

const Home = () => {
    return (
        <div className={styles['control-line']} id='home'>
            <div className={styles['headings-div']}>
                <div className={styles['headings']}>Control Line</div>
                <div className={styles['heading']}>Ensuring ML model responses are authentic</div>
            </div>
            <div>
                <div className={styles['circle']}>
                    <img src={LaptopImage} alt="Control Line"/>
                </div>
            </div>
        </div>
    )
}

export default Home