
import Header from "../Header"
import Home from './Home'
import Importance from './Importance'
import Products from './Products'
import Contact from "../Contact"

const FinTech = () => {
    return (
        <>
            <Header logo = 'green'/>
            <Home/>
            <Products/>
            <Importance/>
            <Contact logo = 'green'/>
        </>
    )
}


export default FinTech