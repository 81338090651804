import React from "react";
import DeepReality from "./components/DeepReality";
import PeepForMedical from "./components/PeepForMedical";
import FinTech from "./components/FinTech";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" Component={DeepReality}/>
        <Route path="/peep-for-medical" Component={PeepForMedical}/>
        <Route path="/fintech" Component={FinTech}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
