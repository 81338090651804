import React from "react";
import styles from './index.module.css'
import Analytics from '../../../assets/digital-tablet-screen-smart-tech@2x.png'
import BusinessImage from '../../../assets/shutterstock_635021405 Frame@2x.png'
import TechnologyImage from '../../../assets/portrait-african-american-programer-online-cloud-computing-laptop-sitting-desk-with-multiple-screens-parsing-code-shoulder-view-app-developer-working-@2x.png'
import FinanceImage from '../../../assets/diverse-business-colleagues-shoo Frame@2x.png'

import PointersImage from '../../../../src/assets/FinTech/pointers.jpeg'

const Importance = () => {

    return (
        <>
            <div className={styles['intuitive']}>
                <div className={styles['health-para']}>Our Humans in the loop approach ensure that we provide 
                <span className={styles['accuracy']}> 100% of Accuracy</span></div>
                <div className={styles['circle']}>
                    <img src={Analytics} alt="Analytics"/>
                </div>
            </div>
            <div className={styles['kyc-headers']}>
                <div className={styles['kyc-header']}>Ready to make a move to modern accounting</div>
                <a className={styles['kyc-button']} target="_blank" rel="noreferrer" href='https://docs.google.com/forms/d/1zZFpSsXX_3mKWZVssudT-TfpYSxdtp-CY0v9fS7eNxk/viewform?edit_requested=true'>Book a demo</a>
            </div>
            <div className={styles['monitoring-uses']}>
                <div className={styles['heading']}>How does it work</div>
                <div className={styles['use-cases']}>
                    <div className={styles['use-case']}>
                        <div className={styles['usecase-circle']}>
                            <img src={BusinessImage} alt="business"/>
                        </div>
                        <div className={styles['usecase-heading']}>Invoice</div>
                    </div>
                    <div className={styles['use-case']}>
                        <div className={styles['usecase-circle']}>
                            <img src={TechnologyImage} alt='technology'/>
                        </div>
                        <div className={styles['usecase-heading']}>Purchase Order</div>
                    </div>
                    <div className={styles['use-case']}>
                        <div className={styles['usecase-circle']}>
                            <img src={FinanceImage} alt="finance"/>
                        </div>
                        <div className={styles['usecase-heading']}>GRN</div>
                    </div>
                </div>
            </div>
            <div className={styles['comparing-div']}>
                <div className={styles['comparing-para']}>
                    <div className={styles['comparing-line']}>Match three of them and see if there are any discrepancies</div>
                    <div className={styles['comparing-line']}>Extract the text, compare the difference and show</div>
                    <div className={styles['comparing-line']}>Segregating the document and mapping it with the right transaction code</div>
                </div>
                <div className={styles['comparing-image']}>
                    <img src={PointersImage} alt="finance"/>
                </div>
            </div>
        </>
    )
}

export default Importance