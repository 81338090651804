import styles from './index.module.css'

import DoctorImage from '../../../assets/medical/homogenius_analysis.png'

const Homogenous = () => {
    return (
        <div className={styles['homogenous']}>
            <div className={styles['combination']}>
                We Combine every type of data point to give you a <span className={styles['combination-orange']}> Homogenous Analysis</span>
            </div>
            <div>
                <img src={DoctorImage} alt = 'Homogenous Analysis' width = {300} height={300}/>
            </div>
        </div>
    )
}


export default Homogenous